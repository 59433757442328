import React, { Component } from 'react';

class FixedBgDiv extends Component {
  render() {
    return (
      <div style={{
        width: '100%',
        height: this.props.height || '50vh',
        backgroundColor: 'rgb(255, 255, 255)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
        //backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        boxShadow: '0px 1px 10px #000000',
        backgroundImage: 'url(' + this.props.bgImg + ')'
      }}>
      </div>
    );
  }
}

export default FixedBgDiv;
