import React, { Component } from 'react';
import NavBar from '../navbar/NavBar';

import Hero from '../hero/Hero';
import About from '../about/About';
import Services from '../services/Services';
import Contact from '../contact/Contact';
import FixedBgDiv from '../FixedBgDiv';

import './Home.css';

import Truck2 from './truck02.jpg';
import Truck3 from './truck03.jpg';

class Home extends Component {
  render() {
    return (
      <div id="home">
        <NavBar />
        <Hero />
        <About />
        <FixedBgDiv bgImg={Truck2} height="60vh"/>
        <Services />
        <FixedBgDiv bgImg={Truck3} height="60vh"/>
        <Contact />
      </div>
    );
  }
}

export default Home;
