import React, { Component } from 'react';
import ScrollTo from 'react-scroll-to-element';

import Arrow1 from './arrowdown01.png';
import TrainPic from './trains01.jpg';

import './hero.css';

class Hero extends Component {
  render() {
    return (
      <div className="herodiv" style={{
          backgroundImage: 'url(' + TrainPic + ')'
        }}>
          <div className="empty"></div>
          <div className="herolayer">
            <p>We Provide <span className="colored">Commitment</span> to Your <span className="colorgreen">Shipping</span> Needs</p>
          </div>
          <ScrollTo type="id" element="about" offset={-45}>
            <div className="heroarrow" style={{
              backgroundImage: 'url(' + Arrow1 + ')'}}>
            </div>
          </ScrollTo>
      </div>
    );
  }
}

export default Hero;
