import React, { Component } from 'react';

import Van1 from './van01.jpg';
import Rgn1 from './rgn01.jpg';
import Pilot1 from './pilot01.jpg';
import Deck1 from './deck01.jpg';
import Safe from './safe2.jpg';

import './services.css';


class Services extends Component {
  render() {
    return (
      <div id="services">
        <h2 className="serviceHeader">We Have the Right Equipment the First Time</h2>
        <div className="servicegallery">

          <div className="serviceCard">
            <div className="servicePic"  style={{
              backgroundImage: 'url(' + Deck1 + ')'}}>
            </div>
            <h3>Open Deck</h3>
            <p>Flat Deck</p>
            <p>Step Deck</p>
            <p>B Train Flat</p>
            <p>Trombone Stretch 80' Flat or Step Deck Configuration</p>
            <p>Removable Gooseneck Stretch</p>
          </div>

          <div className="serviceCard">
            <div className="servicePic" style={{
              backgroundImage: 'url(' + Rgn1 + ')'}}>
            </div>
            <h3>Specialized Heavy Haul</h3>
            <p>Over dimensional </p>
            <p>6-9 Axle Configurations</p>
            <p>Boosters and Jeeps</p>
            <p>Hydraulic RGN's</p>
          </div>

          <div className="serviceCard">
            <div className="servicePic" style={{
              backgroundImage: 'url(' + Van1 + ')'}}>
            </div>
            <h3>Dry Van</h3>
            <p>Air-Ride Tandem and Tridem w/heat</p>
            <p>Logistics Equipped Trailers</p>
            <p>100" Door Openings</p>
            <p>Tail Gate Service</p>
          </div>

          <div className="serviceCard">
            <div className="servicePic" style={{
            backgroundImage: 'url(' + Pilot1 + ')'}}>
            </div>
            <h3>Pilot Car Service</h3>
            <p>Fully Insured and Certified 4X4 Trucks</p>
            <p>F.A.S.T Card Drivers</p>
            <p>Route Surveys</p>
            <p>​High Pole</p>
            <p>Permitting - Transport Permits</p>
            <p>​CB and VHF Radio Equipped Trucks</p>
          </div>

        </div>


        <h2 className="serviceHeader">We Are Proud to Serve Both Drivers and Businesses Alike</h2>
        
        <div className="servicesextra" style={{
            backgroundImage: 'url(' + Safe + ')'}}>
          
          <div className="serviceextrabox">
            <h3>Trucking and Freight</h3>
            <p>Once your freight leaves the loading dock, it's out of your hands but, chances are, not out of your head. When you trust us with the on-time delivery of your freight, we fret the big and small stuff so you don't have to.</p>
          </div>
          
          <div className="serviceextrabox">
            <h3>Specialty Freight</h3>
            <p>We have the knowledge, expertise, equipment, and people to ship the unshippable. Whatever needs to get from point A to point B can be transported by us safely and quickly. If you have a challenge, we'd love to hear it.</p>
          </div>
          
          <div className="serviceextrabox">
            <h3>Domestic shipping</h3>
            <p>We service regional markets from state to state, province to province or city to city.</p>
          </div>
          
          <div className="serviceextrabox">
            <h3>International shipping</h3>
            <p>We deliver to almost every corner of the globe</p>
          </div>
          
          <div className="serviceextrabox">
            <h3>Fragile and High Value</h3>
            <p>We have the right people and equipment to serve your needs.</p>
          </div>
          
          <div className="serviceextrabox">
            <h3>Hazardous materials</h3>
            <p>We comply with all Federal Hazardous Material regulations.</p>
          </div>

        </div>
        <div className="commitmentof">
          <h2 className="serviceHeader">We Believe in Commitment</h2>
          <p>Customer Service </p>
          <p>Safety ​</p>
          <p>Guiding Principles</p>
          <p>Professionalisim</p>
        </div>
      </div>
    );
  }
}

export default Services;
