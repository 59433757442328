import React, { Component } from 'react';

import TruckAboutPic from './truck01.jpg';
import About01 from './about01.jpg';
import Clock from './clock.jpg';
import Knowledge from './knowledge.jpg';
import Shake from './shake.jpg';

import './about.css';

class About extends Component {
  constructor() {
    super();
    this.state = {theDivRev: 'block', theDiv: 'none', toggleDiv: true};
    
  }

  showDiv = () => {
    if(this.state.toggleDiv){
      this.setState({theDivRev: 'none', theDiv: 'block', toggleDiv: false});
    }else{
      this.setState({theDivRev: 'block', theDiv: 'none', toggleDiv: true});
    } 
  }

  render() {
    return (
      <div className="about" id="about">
            <h2 className="aboutHeader">Our Reputation is Built on Excellence in Trucking and Logistics</h2>
            <div className="aboutpicbg" style={{
              backgroundImage: 'url(' + About01 + ')'}}>
              <div className="truckpic" style={{
                backgroundImage: 'url(' + TruckAboutPic + ')'}}>
              </div>
            </div>
            <h2 className="aboutHeader">We Deliver a Constant Focus on Unapproachable Performance</h2>
            <div className="abouttext">
              <p>Our business was created to help business like yours get dependable and excellent transport from the loading dock to loading dock or jobsite.</p>
              <p onClick={this.showDiv} style={{
                display: this.state.theDivRev}}>
                <span className="abouttextreadmore"> Continue reading...</span>
              </p>
              <div className="abouttexthide" style={{
                width: '100%',
                display: this.state.theDiv
              }}>
                <p>The principles of our business have remained steadfast through the years. We offer efficient transport and intelligent supply chain solutions.</p>
                <p>Founded in 2003 as an asset based trucking company. Adrian Morgan Trucking is North America's leading provider of asset based and Third Party Transportation and Logistics services. We provide our customers with the latest technology and vast truck capacity to handle the smallest shipments to large scale projects. Adrian Morgan Trucking is a stable and privately held Corporation managed each day with a simplistic hands on management style designed to ensure our “Unapproachable Performance” for its customers.</p>
                <p>Adrian Morgan Trucking remains a family owned and operated company with its office centrally located in Winnipeg, MB.  With its trucking company, Adrian Morgan Trucking offers dedicated and irregular route highways, less than truck load (LTL), truck load (TL), HotShot/Expedited, Pilot Car, Heavy Specialized and General freight services throughout Canada and the United States.</p>
              </div>
            </div>
            <div className="whyChooseUs">
              <h2 className="aboutHeader">Why Choose Us?</h2>
              <div className="whyChooseCards">

                <div className="whyChooseCard">
                  <div className="cardHeader">
                    <h3>We Deliver Your Hopes, Your Dreams and Your Freight On Time</h3>
                    <div className="whyChooseCardPic" style={{
                      backgroundImage: 'url(' + Clock + ')'}}>
                    </div>
                  </div>
                  <p>Your freight is too important to trust to amateur, fly-by-night trucking companies or unscrupulous freight brokers. We deliver every time, and that's a promise.</p>
                </div>

                <div className="whyChooseCard">
                  <div className="cardHeader">
                    <h3>We Know the Ins and Outs of Transportation and the Supply Chain</h3>
                    <div className="whyChooseCardPic" style={{
                      backgroundImage: 'url(' + Knowledge + ')'}}>
                    </div>
                  </div>
                  <p>The difference, in one word, is experience. Our drivers, dispatch people, customer service representatives, and managment have it in spades.</p>
                </div>

                <div className="whyChooseCard">
                  <div className="cardHeader">
                    <h3>We Are Not Just a Vendor, We Are Your Partner</h3>
                    <div className="whyChooseCardPic" style={{
                      backgroundImage: 'url(' + Shake + ')'}}>
                    </div>
                  </div>
                  <p>Transportation needs fluctuate, grow, and evolve in ways you might be able to anticipate. We can help create not just a solution, but a logistics strategy.</p>
                </div>


              </div>
            </div>
      </div>
    );
  }
}

export default About;
