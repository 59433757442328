import React, { Component } from 'react';
import Map from '../Map';

import Facebook from './facebook.png';
import Linkedin from './linkedin.png';
import Twitter from './twitter.png';


import './contact.css';

class Contact extends Component {
  render() {
    return (
      <div className="contact">
          <div className="testimonials">
            <h2 className="contactHeader">We would love to hear from you!</h2>
            <p>It's our privilege to have earned the trust of innumerable clients through the years. From the smallest parcel to the largest shipment, we've treated each job as the most important one we could perform, until the next one.</p>
            <h3>Testimonials</h3>
            <div className="testimonialstext">
              <p><em>“After dealing with a previous trucking company as they were late again! I figured I would give Adrian Morgan Trucking a try. Was I ever surprised. He had a truck picking up my goods within the hour. I continue to use their services still today. I recommend him to anyone.” Shipper <strong>-Lori L. Ellis</strong></em></p>
              <p><em>“Adrian Morgan has continued to provide me with routes, even when stuck in a bind. I just called him up and shortly after I was picking up the next freight. My family and I will always use his services in the future” Driver <strong>-Roger Ostrander</strong></em></p>  
            </div>
          </div>


          <h2 className="contactHeader" id="contact">Our Team is a Global One That is Experienced in Shipping All Over the World</h2>
          
          <div className="contactres">

            <div className="address">
              <div className="contactMap">
                <Map isMarkerShown />
              </div>
              <div className="addressText">
                <h3>Adrian Morgan Trucking</h3>
                <p>P: <a href="tel:204-691-7983">204-691-7983</a></p>
                <p>F: 877-723-0814</p>
                <p className="theAddress">2061 Brookside Boulevard</p>
                <p>Stony Mountain, MB R0C 3A0</p>
                <p><a href="mailto:dispatch@adrianmorgantrucking.co" alt="email">dispatch@adrianmorgantrucking.co</a></p>
              </div>
            </div>

            <div className="socialMediaSection">
              <a href="https://www.facebook.com/pages/Adrian-Morgan-Trucking/294375377655089" alt="Facebook"><div className="socialMedia" style={{
                backgroundImage: 'url(' + Facebook + ')'}}></div></a>
              <a href="https://www.linkedin.com/company/adrian-morgan-trucking/" alt="Linkedin"><div className="socialMedia" style={{
                backgroundImage: 'url(' + Linkedin + ')'}}></div></a>
              <a><div className="socialMedia" style={{
                backgroundImage: 'url(' + Twitter + ')'}}></div></a>            
            </div>

          </div>
          <div className="copywrite">
            <p>Adrain Morgan Trucking © 2013</p>
          </div>
      </div>
    );
  }
}

export default Contact;
