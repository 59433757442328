import React, { Component } from 'react';
import './navbar.css';
import ScrollTo from 'react-scroll-to-element';
import AMTLogo from './amtlogo1.png';

class NavBar extends Component {
  render() {
    return (
      <div className="navbar">
          <ScrollTo type="id" element="home">
            <div className="amtlogo" style={{
            backgroundImage: 'url(' + AMTLogo + ')'
            }}></div>
          </ScrollTo>
        <div className="menu">
          <ScrollTo type="id" element="home">
            <div>Home</div>
          </ScrollTo>
          <ScrollTo type="id" element="about" offset={-45}>
            <div>About</div>
          </ScrollTo>
          <ScrollTo type="id" element="services" offset={-45}>
            <div>Services</div>
          </ScrollTo>
          <ScrollTo type="id" element="contact" offset={-45}>
            <div>Contact</div>
          </ScrollTo>
        </div>
      </div>
    );
  }
}

export default NavBar;
